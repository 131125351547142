<template>
  <v-menu
    bottom
    left
  >
    <template v-slot:activator="{ on, attrs }">
      <v-btn
        icon
        v-bind="attrs"
        v-on="on"
      >
        <v-icon>{{ icons.mdiDotsVertical }}</v-icon>
      </v-btn>
    </template>
    <!-- props.actionItems Example Array -->
    <!-- ------------------------------- -->
    <!--
    [
      {
        icon: mdiAccount,
        text: 'This is the Text',
        color: 'primary',
        to: { name: 'snowflake-user', params: {} }, (Optional Route),
        executeFunction: i.e. () => executeBindingFunction({ bindingId: connector.id }) (Optional Function Execution)
      },
    ]
    -->

    <v-list
      v-for="item in actionItems"
      :key="item.text"
    >
      <v-list-item
        v-if="!item.hide"
        :to="item.to"
        @click="clickExecution(item)"
      >
        <v-list-item-title>
          <v-icon
            size="20"
            class="me-2"
            :color="item.color"
          >
            {{ item.icon }}
          </v-icon>
          <span :class="`${item.color}--text`">{{ item.text }}</span>
        </v-list-item-title>
      </v-list-item>
    </v-list>
  </v-menu>
</template>
<script>
import { mdiDotsVertical } from '@mdi/js'

export default {
  props: {
    actionItems: {
      type: Array,
      required: true,
    },
  },
  setup() {
    const clickExecution = async item => {
      if (item.executeFunction) {
        return item.executeFunction()
      }

      return null
    }

    return {
      clickExecution,
      icons: {
        mdiDotsVertical,
      },
    }
  },
}
</script>

<style lang="scs" scoped>
</style>
