<template>
  <div>
    <v-row class="match-height">
      <v-col
        cols="12"
      >
        <sync-overview @time-interval-selection="item => timeIntervalSelection = item"></sync-overview>
      </v-col>
      <v-col
        cols="12"
      >
        <sync-overview-list
          v-if="snowBindingTasks"
          @trigger-inactive-tasks="showInactive = !showInactive"
        ></sync-overview-list>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import store from '@/store'
import { computed, onMounted, provide, ref, watch } from '@vue/composition-api'
import _ from 'lodash'
import moment from 'moment-timezone'

import SyncOverview from './SyncOverviewCard.vue'
import SyncOverviewList from './SyncOverviewList.vue'

export default {
  components: {
    SyncOverview,
    SyncOverviewList,
  },
  setup() {
    const showInactive = ref(false)
    provide('showInactive', showInactive)
    if (!store.state.snowBindings?.snowBindingTasks) store.dispatch('snowBindings/syncSnowBindingTasks', { showInactive: true })
    if (!store.state.snowBindings?.snowBindingEvents) store.dispatch('snowBindings/syncSnowBindingEvents')
    const snowBindingTasks = computed(() => store.state.snowBindings.snowBindingTasks)
    provide('snowBindingTasks', snowBindingTasks)

    const connectors = computed(() => store.state.snowBindings.snowBindingConnectors)
    provide('connectors', connectors)

    // Time Interval Properties
    const currentTime = ref(new Date()) // Increments From Mounted setInterval Function for Reactivity
    const timeIntervalSelection = computed(() => store.state.snowBindings.snowBindingsInterval)
    const snowBindingsStartAt = computed(() => store.state.snowBindings.snowBindingsStartAt)
    const snowBindingsEndAt = computed(() => {
      if (new Date()) return moment(new Date()).toDate()

      return store.state.snowBindings.snowBindingsEndAt
    })
    const dateTimeGranularity = ref('hour')
    const chartCategories = ref([])
    provide('chartCategories', chartCategories)

    const setChartCategories = () => {
      // Set Chart Categories (time Intervals)
      let currentIteration = snowBindingsStartAt.value
      const groupArray = []

      // Create a Date Reference Table To Join To
      while (moment(currentIteration).startOf(dateTimeGranularity.value).toDate() <= moment(snowBindingsEndAt.value).startOf(dateTimeGranularity.value).toDate()) {
        groupArray.push(moment(currentIteration).format('hA'))
        currentIteration = moment(currentIteration).add(1, 'hour').toDate()
      }

      chartCategories.value = groupArray
    }

    watch(currentTime, (to, from) => {
      const toDate = moment(to).startOf(dateTimeGranularity.value).valueOf()
      const fromDate = moment(from).startOf(dateTimeGranularity.value).valueOf()
      if (toDate !== fromDate) {
        setChartCategories()
      }
    })
    const timeIntervalItems = ['Today', 'Last Hour', 'Last 3 Days']
    provide('timeIntervalSelection', timeIntervalSelection)
    provide('timeIntervalItems', timeIntervalItems)

    //
    //* ——— Takes in Date and Gives It a Group Name ——————————————————
    //
    const groupBindingData = startTime => moment(startTime).startOf('hour').format('hA')

    //
    //* ——— Group And Sum Data ——————————————————
    //
    const groupAndSumRunData = runData => {
      const groupAndSum = _(runData)
        .groupBy('group')
        .map((group, id) => ({ group: id, success: _.sumBy(group, 'success'), warnings: _.sumBy(group, 'warnings'), errors: _.sumBy(group, 'errors'), inserts: _.sumBy(group, 'inserts'), updates: _.sumBy(group, 'updates'), statics: _.sumBy(group, 'statics'), avgExecutionTime: _.meanBy(group, 'sourceExecutionDuration') }))
        .value()

      return groupAndSum
    }

    //
    //* ——— Group Reference (Date) Table On Data ——————————————————
    //
    const groupDataOnCategories = data => {
      const catData = []
      chartCategories.value.forEach(group => {
        const dataIndex = data.findIndex(i => i.group === group)
        if (dataIndex !== -1) catData.push(data[dataIndex])
        else catData.push({ group })
      })

      return catData
    }

    // ————————————————————————————————————
    //* ——— Chart Data
    // ————————————————————————————————————

    //
    //* ——— Build Binding Events by Binding ——————————————————
    //
    const storeEventData = computed(() => store.state.snowBindings.snowBindingEvents)
    const bindingEventData = computed(() => {
      if (!storeEventData.value) return []
      const bindingData = []
      storeEventData.value.forEach(event => {
        bindingData.push({ ...event, group: groupBindingData(event.executionStartTime) })
      })

      return bindingData
    })
    provide('bindingEventData', bindingEventData)

    //
    //* ——— Builds Summary Data Across ALL Bindings ——————————————————
    //
    const chartDataSummary = computed(() => {
      if (!bindingEventData.value) return null
      let summaryData = bindingEventData.value.map(m => m)

      // // Join and Sum Data Together
      summaryData = groupAndSumRunData(summaryData)

      // Join Data To Reference Table
      summaryData = groupDataOnCategories(summaryData)

      summaryData = [
        {
          name: 'SUCCEEDED',
          data: summaryData.map(m => m.success || 0),
        },
        {
          name: 'WARNING',
          data: summaryData.map(m => m.warnings || 0),
        },
        {
          name: 'FAILED',
          data: summaryData.map(m => m.errors || 0),
        },
      ]

      return summaryData
    })
    provide('chartDataSummary', chartDataSummary)

    onMounted(() => {
      setChartCategories()

      // Watches Current Time To See If New Time Rows Need To Be Added
      window.setInterval(() => {
        currentTime.value = new Date()
      }, 1000)
    })

    return { chartCategories, snowBindingsStartAt, snowBindingsEndAt, timeIntervalSelection, showInactive, connectors, snowBindingTasks, chartDataSummary, bindingEventData }
  },
}
</script>
