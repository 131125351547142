<template>
  <v-container
    fluid
    fill-height
  >
    <v-layout child-flex>
      <v-card>
        <v-card-title class="align-start">
          <v-row>
            <v-col
              sm="12"
              md="4"
            >
              <v-icon
                left
                color="info"
              >
                {{ icons.mdiSnowflake }}
              </v-icon>
              <span style="padding-top: 2px">Snowflake Connectors</span>
              <!-- FILTERS -->
              <!-- <v-btn
                icon
                class="ml-2"
              >
                <v-icon>
                  {{ icons.mdiFilterMenu }}
                </v-icon>
              </v-btn> -->
              <!-- SEARCH BUTTON -->
              <v-btn
                icon
                class="ml-2"
                @click="searchShowing = !searchShowing"
              >
                <v-icon>
                  {{ icons.mdiMagnify }}
                </v-icon>
              </v-btn>
            </v-col>
            <!-- SEARCH BOX -->
            <v-col
              sm="12"
              md="4"
            >
              <v-text-field
                v-if="searchShowing"
                v-model="searchString"
                class="ml-2 mt-0"
                dense
                :append-icon="icons.mdiMagnify"
                label="Search"
                outlined
                single-line
                hide-details
                clearable
              ></v-text-field>
            </v-col>
            <v-col
              align="end"
              sm="12"
              md="4"
            >
              <!-- Action Button -->
              <v-btn
                small
                color="primary"
                depressed
                class="mr-2"
                :to="{ name: `binding-creation` }"
              >
                <v-icon
                  dark
                  left
                >
                  {{ icons.mdiPlus }}
                </v-icon>
                New Snow Binding
              </v-btn>
              <v-btn
                icon
                @click="$emit('trigger-inactive-tasks')"
              >
                <v-icon>{{ !showInactive ? icons.mdiDeleteRestore : icons.mdiDeleteCircleOutline }}</v-icon>
              </v-btn>
            </v-col>
          </v-row>
        </v-card-title>
        <!-- Start Connector Table -->
        <!-- --------------------- -->
        <v-data-table
          :headers="tableHeaders"
          :items="connectorList"
          :expanded.sync="tableRowsExpanded"
          show-expand
        >
          <!-- Sync Name -->
          <template
            v-slot:item.syncName="{ item }"
          >
            <router-link
              :to="item.isCustomConnector ? {} : { name: 'edit-binding', params: { id: item.id } }"
              style="text-decoration:none"
            >
              <div
                class="d-flex mt-3 ml-3"
                style="min-width: 300px"
              >
                <v-avatar size="60">
                  <v-img
                    contain
                    :src="item.avatar"
                  ></v-img>
                </v-avatar>
                <div
                  class="ms-3 mt-2"
                >
                  <p class="font-weight-bold mb-0">
                    {{ item.title }}
                  </p>
                  <span class="black--text font-weight-bold text-xs">{{ item.subtitle }}</span>
                </div>
              </div>
            </router-link>
          </template>
          <!-- Status -->
          <template v-slot:item.status="{ item }">
            <v-chip
              small
              color="primary"
              :class="`v-chip-light-bg ${item.chipColor}--text font-weight-semibold`"
            >
              {{ item.chipText }}
            </v-chip>
            <v-progress-linear
              v-if="item.progressBar"
              class="mt-2"
              color="primary"
              height="20"
              :value="item.progressBar"
              striped
              rounded
            >
              <template>
                <strong :class="item.progress > 50 ? 'white--text' : 'black--text'">{{ item.progress }}%</strong>
              </template>
            </v-progress-linear>
            <span
              v-if="item.chipSubText"
              class="text-caption"
            >{{ item.chipSubText }}</span>
          </template>
          <!-- Last Run -->
          <template v-slot:item.lastRun="{ item }">
            <span class="primary--text">{{ item.lastRun ? `${$moment(item.lastRun).fromNow()}` : 'Never' }}</span>
          </template>
          <!-- Recent Runs -->
          <template v-slot:item.recentRuns="{ item }">
            <vue-apex-charts
              id="sync-chart"
              height="100"
              :options="chartOptions"
              :series="chartData(item)"
            ></vue-apex-charts>
          </template>
          <!-- Action Items -->
          <template
            v-slot:item.actions="{ item }"
          >
            <!-- Action List ( If Not Running State) -->
            <actions-menu
              v-if="item.status !== 'RUNNING'"
              :action-items="actionItems(item)"
            ></actions-menu>
            <!-- Stop Button (If Running State)-->
            <v-btn
              v-if="item.status === 'RUNNING'"
              color="error"
              icon
              @click="terminateCurrentJobRun(item)"
            >
              <v-icon>{{ icons.mdiCloseOctagon }}</v-icon>
            </v-btn>
          </template>
          <!-- Table Expansion -->
          <!-- --------------- -->
          <template v-slot:expanded-item="{ headers, item }">
            <td :colspan="headers.length">
              <!-- If Errors -->
              <v-col
                v-if="item.chipText === 'ERRORS'"
                sm="12"
              >
                <v-alert type="error">
                  {{ item.error }}
                </v-alert>
              </v-col>
              <v-col>
                <p><strong>Schedule:</strong> {{ item.cron === 'afterJob' ? `After Previous Job Completion: ${item.afterTaskText ? item.afterTaskText.name : ''}` : item.enabled ? cronReadable(item) : 'DISABLED' }}</p>
                <p><strong>Last Run Started:</strong> {{ item.lastRunStartedAt }}</p>
                <p><strong>Last Run Completed:</strong> {{ item.lastRunCompletedAt }}</p>
                <p><strong>Last Run Duration:</strong> {{ item.lastRunDuration }}</p>
              </v-col>
            </td>
          </template>
        </v-data-table>
        <v-row
          v-if="connectorList.length === 0"
          class="ma-5"
        >
          <span>No Connectors Have Been Created</span>
        </v-row>
      </v-card>
    </v-layout>
  </v-container>
</template>

<script>
/* eslint-disable no-nested-ternary */
import { updateBindingDoc } from '@/firestore'
import { formatNumbers } from '@/functions'
import { executeBindingFunction, snowBindingAfterJobChange, snowBindingSchedule } from '@/functions/snowBindings'
import store from '@/store'
import { getVuetify } from '@core/utils'
import { mdiCloseOctagon, mdiCogSync, mdiDelete, mdiDeleteCircleOutline, mdiDeleteRestore, mdiDotsVertical, mdiFilterMenu, mdiMagnify, mdiPlus, mdiSnowflake, mdiTextBoxEdit } from '@mdi/js'
import { computed, inject, ref } from '@vue/composition-api'
import cronstrue from 'cronstrue'
import _ from 'lodash'
import moment from 'moment-timezone'
import VueApexCharts from 'vue-apexcharts'
import ActionsMenu from '../../components/global/ActionsMenu.vue'
import snowBindingConnectorLogos from './connectors/snowbindingConnectorLogos'

// import { kFormatter } from '@core/utils/filter'

export default {
  components: {
    ActionsMenu,
    VueApexCharts,
  },
  setup() {
    const tableHeaders = [
      {
        text: 'Sync Name',
        align: 'start',
        value: 'syncName',
        width: '20%',
      },
      {
        text: 'Status',
        align: 'center',
        value: 'status',
        width: '10%',
      },
      {
        text: 'Last Run',
        align: 'center',
        value: 'lastRun',
        width: '10%',
      },
      {
        text: 'Recent Runs',
        align: 'center',
        value: 'recentRuns',
        sortable: false,
        width: '40%',
      },
      {
        text: 'Actions',
        align: 'center',
        value: 'actions',
        sortable: false,
        width: '10%',
      },
    ]

    const searchShowing = ref(false)
    const searchString = ref(null)

    // Human Readable Cron Description
    const cronReadable = item => `Runs ${cronstrue.toString(item.cron || '* * * * *')}`
    const tableRowsExpanded = []
    const $vuetify = getVuetify()
    const showInactive = inject('showInactive')
    const snowBindingTasks = inject('snowBindingTasks')
    const chartCategories = inject('chartCategories')
    const snowBindingConnectors = computed(() => store.state.snowBindings.snowBindingTemplates)
    const account = computed(() => store.state.accountProfile)
    const bindingEventData = inject('bindingEventData')
    const getBindingType = type => (type === 'ingress' ? 'ingestion' : 'dispatch')

    // const dialogContinue = computed(() => store.state.globalDialogResponse)

    const chartData = connector => {
      let connectorFilter = []
      if (bindingEventData.value) {
        connectorFilter = bindingEventData.value.filter(f => String(f.bindingId) === String(connector.id))
      }
      const data = _(connectorFilter)
        .groupBy('group')
        .map((m, group) => ({ group, success: _.sumBy(m, 'success') || 0, warnings: _.sumBy(m, 'warnings') || 0, errors: _.sumBy(m, 'errors') || 0 }))
        .value()

      // Create Array Using Categories To Find Correct Index Position
      const categorizedArray = []
      chartCategories.value.forEach(category => {
        const catMatch = data.filter(f => f.group === category)[0]
        categorizedArray.push(catMatch || null)
      })
      const dataArray = [
        {
          name: 'SUCCEEDED',
          data: categorizedArray.map(m => (m ? m.success || 0 : 0)),
        },
        {
          name: 'WARNING',
          data: categorizedArray.map(m => (m ? m.warnings || 0 : 0)),
        },
        {
          name: 'FAILED',
          data: categorizedArray.map(m => (m ? m.errors || 0 : 0)),
        },
      ]

      return dataArray
    }

    // Binding Execution with Full Historical Reload (Launches Alert Confirmation)
    const executeHistoricalLoad = async ({ bindingId }) => {
      const payload = { bindingId, historicalLoad: true }
      store.commit('setGlobalDialog', { type: 'warning', show: true, title: 'Are You Sure You Want To Continue?', message: 'This will clear all previous incremental loads and reload the data completely.', buttonText: 'RELOAD ALL DATA', continueFunction: () => executeBindingFunction(payload) })
    }

    const terminateCurrentJobRun = async item => {
      const data = { status: 'TERMINATE' }
      updateBindingDoc({ account: account.value, docId: item.id, data })
    }

    const disableSnowBinding = async ({ task }) => {
      const taskObject = { ...task, name: task.title }

      // Pause Cron Job
      if (task.cron && task.cron !== 'afterJob') {
        await snowBindingSchedule({ account: account.value, task: taskObject, pause: true })
      }

      // Update Parent Job to Disabled
      if (task.cron === 'afterJob') {
        await snowBindingAfterJobChange({ account: account.value, task, enabled: false })
      }

      const data = { enabled: false }
      await updateBindingDoc({ account: account.value, docId: task.id, data })
    }

    const enableSnowBinding = async ({ task }) => {
      const taskObject = { ...task, name: task.title }

      // const data = { enabled: true }

      // Resume Cron Job
      if (task.cron && task.cron !== 'afterJob') {
        await snowBindingSchedule({ account: account.value, task: taskObject, resume: true })
      }

      // Update Parent Job to Disabled
      if (task.cron === 'afterJob') {
        await snowBindingAfterJobChange({ account: account.value, task, enabled: true })
      }

      const data = { enabled: true }
      await updateBindingDoc({ account: account.value, docId: task.id, data })
    }

    const deleteSnowBinding = async ({ task }) => {
      const taskObject = { ...task, name: task.title }
      if (task.cron && task.cron !== 'afterJob') {
        await snowBindingSchedule({ account: account.value, task: taskObject, cron: null })
      }

      // Update Parent Job to Disabled
      if (task.cron === 'afterJob') {
        await snowBindingAfterJobChange({ account: account.value, task, enabled: false })
      }
      const data = { isDeleted: true, enabled: false }
      updateBindingDoc({ account: account.value, docId: task.id, data })
    }

    const restoreSnowBinding = async ({ bindingId }) => {
      const data = { isDeleted: false }
      updateBindingDoc({ account: account.value, docId: bindingId, data })
    }

    const actionItems = connector => [
      {
        icon: mdiTextBoxEdit,
        text: 'Edit SnowBinding',
        color: 'primary',
        to: { name: 'edit-binding', params: { id: connector.id } },
        hide: connector.isCustomConnector || connector.status === 'RUNNING',
      },
      {
        icon: mdiCogSync,
        text: 'Run SnowBinding',
        color: 'success',
        hide: connector.isDeleted || connector.status === 'RUNNING',
        executeFunction: () => executeBindingFunction({ bindingId: connector.id }),
      },
      {
        icon: mdiCogSync,
        text: 'Full Historical Reload',
        color: 'warning',
        hide: connector.isDeleted || connector.status === 'RUNNING',
        executeFunction: () => executeHistoricalLoad({ bindingId: connector.id }),
      },
      {
        icon: mdiCogSync,
        text: 'Terminate Current Job Run',
        color: 'error',
        hide: connector.status !== 'RUNNING',
        executeFunction: () => terminateCurrentJobRun({ bindingId: connector.id }),
      },
      {
        icon: mdiCogSync,
        text: 'Enable SnowBinding',
        color: 'success',
        hide: connector.enabled || !connector.cron,
        executeFunction: () => enableSnowBinding({ task: connector }),
      },
      {
        icon: mdiCogSync,
        text: 'Disable SnowBinding',
        color: 'secondary',
        hide: !connector.enabled || connector.status === 'RUNNING',
        executeFunction: () => disableSnowBinding({ task: connector }),
      },
      {
        icon: mdiDelete,
        text: 'Delete SnowBinding',
        color: 'error',
        hide: connector.isDeleted || connector.status === 'RUNNING',
        executeFunction: () => deleteSnowBinding({ task: connector }),
      },
      {
        icon: mdiDeleteRestore,
        text: 'Restore SnowBinding',
        color: 'warning',
        hide: !connector.isDeleted,
        executeFunction: () => restoreSnowBinding({ bindingId: connector.id, bindingName: connector.title, enabled: connector.enabled }),
      },
    ]

    const chartOptions = computed(() => ({
      tooltip: { enabled: true },
      colors: [$vuetify.theme.currentTheme.success, $vuetify.theme.currentTheme.warning, $vuetify.theme.currentTheme.error],
      chart: {
        type: 'bar',
        stacked: true,
        toolbar: {
          show: false,
        },
      },
      plotOptions: {
        bar: {
          borderRadius: 5,
          columnWidth: '35%',
          startingShape: 'rounded',
          endingShape: 'rounded',
        },
      },
      xaxis: {
        categories: chartCategories.value,
        axisBorder: {
          show: false,
        },
        axisTicks: {
          show: true,
        },
        labels: {
          show: true,
        },
      },
      yaxis: {
        labels: {
          show: false,
        },
      },
      grid: {
        show: false,
      },
      dataLabels: {
        formatter: value => formatNumbers(value, 0),
        position: 'top',
        enabled: true,
        style: { colors: ['#333'] },
      },
      legend: {
        show: false,
      },
      stroke: {
        curve: 'smooth',
        width: 0,
        lineCap: 'round',
        colors: ['#fff'],
      },
    }))

    const connectors = inject('connectors')

    const getDuration = milliseconds => {
      if (!milliseconds || milliseconds < 0) return null
      const momentFormatted = moment.duration(milliseconds)
      const hours = momentFormatted.hours()
      const minutes = momentFormatted.minutes()
      const seconds = momentFormatted.seconds()

      return `${hours ? `${hours}h - ` : ''}${minutes ? `${minutes}m - ` : ''}${seconds ? `${seconds}s` : ''}`
    }

    const connectorList = computed(() => {
      let jobs = []
      const bindingTasks = snowBindingTasks.value
      bindingTasks.forEach(task => {
        if (!showInactive.value && task.isDeleted) return
        const connector = snowBindingConnectors.value.filter(f => f.id === task.appId)[0]
        const tasks = connector ? [].concat(...(connector.ingressTasks || []), ...(connector.egressTasks || [])) : []
        const t = tasks.filter(f => f.id === task.taskId)[0]
        const { status, enabled, cron, scheduleCreation, runningStatus, loadMethod } = task
        // eslint-disable-next-line no-nested-ternary
        const chipText = status === 'TERMINATE' ? 'TERMINATING...' : !enabled && !['RUNNING', 'WAITING', 'ERROR'].includes(status) ? 'DISABLED' : status === 'SUCCESS' ? 'HEALTHY' : status === 'ERROR' ? 'ERRORS' : ['RUNNING', 'WAITING'].includes(status) ? 'RUNNING' : 'WAITING ON RUN'
        // eslint-disable-next-line no-nested-ternary
        const chipColor = status === 'TERMINATE' ? 'error' : ['RUNNING', 'WAITING'].includes(status) ? 'primary' : status === 'ERROR' ? 'error' : !enabled ? 'secondary' : status === 'SUCCESS' ? 'success' : 'secondary'
        const chartColor = '#9155fd'
        const data = {
          id: task.id,
          appName: task.appName,
          status,
          avatar: snowBindingConnectorLogos(task.appId),
          title: task?.name,
          subtitle: t?.task || 'SnowFlow Custom Connector',
          taskType: task.taskType,
          chipText,
          chipColor,
          chartColor,
          cron,
          enabled,
          isDeleted: task?.isDeleted,
          lastRunStartedAt: runningStatus?.runStartedAt,
          // eslint-disable-next-line no-nested-ternary
          lastRunCompletedAt: runningStatus?.runCompletedAt && runningStatus?.runCompletedAt > runningStatus?.runStartedAt ? runningStatus?.runCompletedAt : runningStatus?.runStartedAt, // chipText === 'RUNNING' ? new Date() :
          lastRunDuration: runningStatus?.errors ? null : runningStatus?.runStartedAt && runningStatus?.runCompletedAt ? getDuration(runningStatus?.runCompletedAt - runningStatus?.runStartedAt) : null,
          error: runningStatus?.errors ? JSON.stringify(runningStatus.errors) : null,
          progress: ['RUNNING', 'WAITING'].includes(status) && (runningStatus?.totalRowsProcessed || runningStatus?.totalRowsProcessed === 0) && runningStatus?.totalRowsToProcess ? Math.ceil((runningStatus.totalRowsProcessed / runningStatus.totalRowsToProcess) * 100) : null,
          totalRowsToProcess: runningStatus?.totalRowsToProcess,
        }
        data.progress = data.progress > 100 ? 100 : data.progress
        if (task.taskType === 'egress') {
          data.progressBar = loadMethod === 'compareTables' && !runningStatus?.tableCreationCompleted ? null : !runningStatus?.tableCreationCompleted ? null : (data.progress || data.progress === 0) && data.progress < 10 ? 10 : data.progress ? data.progress : null
          data.chipSubText = !['RUNNING', 'WAITING'].includes(status) || data.progressBar ? null : loadMethod === 'compareTables' && !runningStatus?.tableCreationCompleted ? 'Gathering Data...' : !runningStatus?.tableComparisonCompleted ? 'Getting Table Changes...' : null
        } else {
          data.progressBar = !runningStatus?.sourceIngestionCompleted ? null : (data.progress || data.progress === 0) && data.progress < 10 ? 10 : data.progress ? data.progress : null
          data.chipSubText = !['RUNNING', 'WAITING'].includes(status) || data.progressBar ? null : !runningStatus?.sourceIngestionCompleted ? `${data.appName ? `Getting Data From ${data.appName}` : 'Getting Data...'}` : null
        }
        if (['RUNNING', 'WAITING'].includes(status) && data.totalRowsToProcess) data.chipText = `${formatNumbers(data.totalRowsToProcess)} EVENTS`
        data.lastRun = data.lastRunCompletedAt && data.lastRunCompletedAt > data.lastRunStartedAt ? data.lastRunCompletedAt : data.lastRunStartedAt ? data.lastRunStartedAt : null
        // eslint-disable-next-line prefer-destructuring
        if (cron === 'afterJob') data.afterTaskText = store.state.snowBindings.snowBindingTasks.filter(f => f.id === scheduleCreation.afterJob)[0]
        if (!connector) data.isCustomConnector = true
        jobs.push(data)
      })
      jobs = jobs.filter(f => f.title)

      // Order by Running, Errored (and Enabled), Last Completed, Last Run Time
      jobs = jobs.map(m => ({ ...m, sortKey: ['RUNNING', 'WAITING'].includes(m.status) ? `9${m.lastRun?.valueOf()}` : m.enabled && ['ERROR', 'TERMINATE'].includes(m.status) ? `8${m.lastRun?.valueOf()}` : !m.enabled ? `0${m.lastRun?.valueOf()}` : `${m.lastRun?.valueOf()}` }))

      // Sort Runs By Most Recent Run
      jobs = jobs.sort((a, b) => (a.sortKey > b.sortKey ? -1 : 1))

      // If Search Is Activated
      // Filter Users
      if (searchShowing.value && searchString.value) {
        const filteredJobs = []
        jobs.forEach(job => {
          const searchStringConcat = `${job.title} ${job.subtitle}`
          if (searchStringConcat.toLowerCase().includes(searchString.value.toLowerCase())) filteredJobs.push(job)
        })
        jobs = filteredJobs
      }

      return jobs
    })

    const getChartConfig = value => {
      const options = JSON.parse(JSON.stringify(chartOptions))
      options.colors = [value]

      return options
    }

    return {
      searchString,
      searchShowing,
      getBindingType,
      cronReadable,
      tableRowsExpanded,
      tableHeaders,
      terminateCurrentJobRun,
      disableSnowBinding,
      enableSnowBinding,
      showInactive,
      snowBindingTasks,
      actionItems,
      connectorList,
      connectors,
      chartData,
      chartCategories,
      chartOptions,
      getChartConfig,
      icons: {
        mdiMagnify,
        mdiFilterMenu,
        mdiCloseOctagon,
        mdiDeleteCircleOutline,
        mdiDeleteRestore,
        mdiPlus,
        mdiDotsVertical,
        mdiSnowflake,
      },
    }
  },
}
</script>

<style lang="scss" scoped>
.v-data-table-header th {
  white-space: nowrap;
}
</style>
