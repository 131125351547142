<template>
  <v-card>
    <v-card-text>
      <v-row>
        <v-col
          cols="12"
          md="7"
          class="sync-chart-col"
        >
          <v-card-title class="px-0 pt-0">
            Sync Overview
            <v-menu
              bottom
              left
            >
              <template v-slot:activator="{ on, attrs }">
                <v-chip
                  class="ml-3"
                  v-bind="attrs"
                  v-on="on"
                >
                  {{ timeIntervalSelection }}
                </v-chip>
              </template>
              <v-list
                v-for="item in timeIntervalItems"
                :key="item"
              >
                <v-list-item
                  @click="$emit('time-interval-selection', item)"
                >
                  <v-list-item-title>
                    <span>{{ item }}</span>
                  </v-list-item-title>
                </v-list-item>
              </v-list>
            </v-menu>
          </v-card-title>
          <vue-apex-charts
            v-if="chartData"
            id="sync-chart"
            height="200"
            :options="chartOptions"
            :series="chartData"
          ></vue-apex-charts>
        </v-col>

        <v-col
          cols="12"
          md="5"
        >
          <v-card
            elevation="0"
          >
            <!-- Title/Header -->
            <v-card-title class="align-start pt-0 flex-nowrap">
              <div>
                <p class="mb-0 font-weight-bold text-2xl">
                  Total Sync Events: {{ formatNumbers(totalEvents.totals) }}
                </p>
                <!-- <small class="text--secondary text-xs text-no-wrap">Last month balance $234.40k</small> -->
              </div>
              <v-spacer></v-spacer>
              <v-btn
                icon
                small
                class="me-n7 mt-n1"
              >
                <v-icon size="22">
                  {{ icons.mdiDotsVertical }}
                </v-icon>
              </v-btn>
            </v-card-title>

            <v-card-text class="pb-0 pt-3">
              <!-- List -->
              <v-list
                two-line
                subheader
              >
                <!-- List Item: Success -->
                <v-list-item class="pa-0">
                  <v-list-item-avatar
                    class="v-avatar-light-bg success--text justify-center"
                    size="40"
                    rounded
                  >
                    <v-icon
                      size="30"
                      color="success"
                    >
                      {{ icons.mdiCheckBold }}
                    </v-icon>
                  </v-list-item-avatar>
                  <v-list-item-content>
                    <v-list-item-title class="font-weight-semibold">
                      {{ formatNumbers(totalEvents.success) }}
                    </v-list-item-title>
                    <v-list-item-subtitle>Succeeded Events</v-list-item-subtitle>
                  </v-list-item-content>
                </v-list-item>
                <!-- List Item: Warnings -->
                <v-list-item
                  v-if="totalEvents.warning"
                  class="pa-0"
                >
                  <v-list-item-avatar
                    class="v-avatar-light-bg error--text justify-center"
                    size="40"
                    rounded
                  >
                    <v-icon
                      size="30"
                      color="warning"
                    >
                      {{ icons.mdiAlertCircleOutline }}
                    </v-icon>
                  </v-list-item-avatar>
                  <v-list-item-content>
                    <v-list-item-title class="font-weight-semibold">
                      {{ formatNumbers(totalEvents.warning) }}
                    </v-list-item-title>
                    <v-list-item-subtitle>Warning Events</v-list-item-subtitle>
                  </v-list-item-content>
                </v-list-item>
                <!-- List Item: Failures -->
                <v-list-item
                  v-if="totalEvents.error"
                  class="pa-0"
                >
                  <v-list-item-avatar
                    class="v-avatar-light-bg error--text justify-center"
                    size="40"
                    rounded
                  >
                    <v-icon
                      size="30"
                      color="error"
                    >
                      {{ icons.mdiExclamationThick }}
                    </v-icon>
                  </v-list-item-avatar>
                  <v-list-item-content>
                    <v-list-item-title class="font-weight-semibold">
                      {{ formatNumbers(totalEvents.error) }}
                    </v-list-item-title>
                    <v-list-item-subtitle>Failed Events</v-list-item-subtitle>
                  </v-list-item-content>
                </v-list-item>
              </v-list>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
    </v-card-text>
  </v-card>
</template>

<script>
import VueApexCharts from 'vue-apexcharts'
// eslint-disable-next-line object-curly-newline
import { formatNumbers } from '@/functions'
import { getVuetify } from '@core/utils'
import { kFormatter } from '@core/utils/filter'
import { mdiAlertCircleOutline, mdiChartBar, mdiCheckBold, mdiCurrencyUsd, mdiDotsVertical, mdiExclamationThick } from '@mdi/js'
import { computed, inject } from '@vue/composition-api'

export default {
  components: {
    VueApexCharts,
  },
  setup() {
    const $vuetify = getVuetify()
    const chartData = inject('chartDataSummary')
    const chartCategories = inject('chartCategories')
    const timeIntervalSelection = inject('timeIntervalSelection')
    const timeIntervalItems = inject('timeIntervalItems')
    const totalEvents = computed(() => {
      let data = chartData.value
      let success = 0
      let warning = 0
      let error = 0
      let totals = 0
      if (chartData.value) {
        chartData.value.forEach(type => {
          if (type.name === 'SUCCEEDED') {
            type.data.forEach(row => {
              success += row
              totals += row
            })
          }
          if (type.name === 'WARNING') {
            type.data.forEach(row => {
              warning += row
              totals += row
            })
          }
          if (type.name === 'FAILED') {
            type.data.forEach(row => {
              error += row
              totals += row
            })
          }
        })
      }
      data = { success, warning, error, totals }

      return data
    })

    const chartOptions = computed(() => ({
      colors: [$vuetify.theme.currentTheme.success, $vuetify.theme.currentTheme.error],
      chart: {
        type: 'bar',
        stacked: true,
        toolbar: {
          show: false,
        },
      },
      plotOptions: {
        bar: {
          borderRadius: 10,
          columnWidth: '35%',
          startingShape: 'rounded',
          endingShape: 'rounded',
        },
      },
      xaxis: {
        categories: chartCategories.value,
        axisBorder: {
          show: false,
        },
        axisTicks: {
          show: false,
        },
      },
      yaxis: {
        labels: {
          formatter: value => kFormatter(value, 0),
        },
      },
      grid: {
        strokeDashArray: 7,
      },
      dataLabels: {
        formatter: value => formatNumbers(value, 0),
        position: 'top',
        enabled: true,
        style: { colors: ['#333'] },
      },
      legend: {
        show: false,
      },
      stroke: {
        curve: 'smooth',
        width: 6,
        lineCap: 'round',
        colors: ['#fff'],
      },
      responsive: [
        {
          breakpoint: 1650,
          options: {
            plotOptions: {
              bar: {
                borderRadius: 10,
                columnWidth: '45%',
              },
            },
          },
        },
      ],
    }))

    return {
      chartCategories,
      formatNumbers,
      totalEvents,
      timeIntervalItems,
      timeIntervalSelection,
      chartData,
      chartOptions,

      icons: {
        mdiDotsVertical,
        mdiCurrencyUsd,
        mdiChartBar,
        mdiCheckBold,
        mdiExclamationThick,
        mdiAlertCircleOutline,
      },
    }
  },
}
</script>

<style lang="scss">
@import '~@core/preset/preset/mixins.scss';
@import '~vuetify/src/styles/styles.sass';
@media #{map-get($display-breakpoints, 'sm-and-up')} {
  .sync-chart-col {
    @include ltr() {
      border-right: thin solid;
    }
    @include rtl() {
      border-left: thin solid;
    }
  }
}
@include theme--child(sync-chart-col) using ($material) {
  @media #{map-get($display-breakpoints, 'sm-and-up')} {
    border-color: map-get($material, 'dividers');
  }
}
</style>
