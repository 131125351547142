var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{attrs:{"fluid":"","fill-height":""}},[_c('v-layout',{attrs:{"child-flex":""}},[_c('v-card',[_c('v-card-title',{staticClass:"align-start"},[_c('v-row',[_c('v-col',{attrs:{"sm":"12","md":"4"}},[_c('v-icon',{attrs:{"left":"","color":"info"}},[_vm._v(" "+_vm._s(_vm.icons.mdiSnowflake)+" ")]),_c('span',{staticStyle:{"padding-top":"2px"}},[_vm._v("Snowflake Connectors")]),_c('v-btn',{staticClass:"ml-2",attrs:{"icon":""},on:{"click":function($event){_vm.searchShowing = !_vm.searchShowing}}},[_c('v-icon',[_vm._v(" "+_vm._s(_vm.icons.mdiMagnify)+" ")])],1)],1),_c('v-col',{attrs:{"sm":"12","md":"4"}},[(_vm.searchShowing)?_c('v-text-field',{staticClass:"ml-2 mt-0",attrs:{"dense":"","append-icon":_vm.icons.mdiMagnify,"label":"Search","outlined":"","single-line":"","hide-details":"","clearable":""},model:{value:(_vm.searchString),callback:function ($$v) {_vm.searchString=$$v},expression:"searchString"}}):_vm._e()],1),_c('v-col',{attrs:{"align":"end","sm":"12","md":"4"}},[_c('v-btn',{staticClass:"mr-2",attrs:{"small":"","color":"primary","depressed":"","to":{ name: "binding-creation" }}},[_c('v-icon',{attrs:{"dark":"","left":""}},[_vm._v(" "+_vm._s(_vm.icons.mdiPlus)+" ")]),_vm._v(" New Snow Binding ")],1),_c('v-btn',{attrs:{"icon":""},on:{"click":function($event){return _vm.$emit('trigger-inactive-tasks')}}},[_c('v-icon',[_vm._v(_vm._s(!_vm.showInactive ? _vm.icons.mdiDeleteRestore : _vm.icons.mdiDeleteCircleOutline))])],1)],1)],1)],1),_c('v-data-table',{attrs:{"headers":_vm.tableHeaders,"items":_vm.connectorList,"expanded":_vm.tableRowsExpanded,"show-expand":""},on:{"update:expanded":function($event){_vm.tableRowsExpanded=$event}},scopedSlots:_vm._u([{key:"item.syncName",fn:function(ref){
var item = ref.item;
return [_c('router-link',{staticStyle:{"text-decoration":"none"},attrs:{"to":item.isCustomConnector ? {} : { name: 'edit-binding', params: { id: item.id } }}},[_c('div',{staticClass:"d-flex mt-3 ml-3",staticStyle:{"min-width":"300px"}},[_c('v-avatar',{attrs:{"size":"60"}},[_c('v-img',{attrs:{"contain":"","src":item.avatar}})],1),_c('div',{staticClass:"ms-3 mt-2"},[_c('p',{staticClass:"font-weight-bold mb-0"},[_vm._v(" "+_vm._s(item.title)+" ")]),_c('span',{staticClass:"black--text font-weight-bold text-xs"},[_vm._v(_vm._s(item.subtitle))])])],1)])]}},{key:"item.status",fn:function(ref){
var item = ref.item;
return [_c('v-chip',{class:("v-chip-light-bg " + (item.chipColor) + "--text font-weight-semibold"),attrs:{"small":"","color":"primary"}},[_vm._v(" "+_vm._s(item.chipText)+" ")]),(item.progressBar)?_c('v-progress-linear',{staticClass:"mt-2",attrs:{"color":"primary","height":"20","value":item.progressBar,"striped":"","rounded":""}},[[_c('strong',{class:item.progress > 50 ? 'white--text' : 'black--text'},[_vm._v(_vm._s(item.progress)+"%")])]],2):_vm._e(),(item.chipSubText)?_c('span',{staticClass:"text-caption"},[_vm._v(_vm._s(item.chipSubText))]):_vm._e()]}},{key:"item.lastRun",fn:function(ref){
var item = ref.item;
return [_c('span',{staticClass:"primary--text"},[_vm._v(_vm._s(item.lastRun ? ("" + (_vm.$moment(item.lastRun).fromNow())) : 'Never'))])]}},{key:"item.recentRuns",fn:function(ref){
var item = ref.item;
return [_c('vue-apex-charts',{attrs:{"id":"sync-chart","height":"100","options":_vm.chartOptions,"series":_vm.chartData(item)}})]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [(item.status !== 'RUNNING')?_c('actions-menu',{attrs:{"action-items":_vm.actionItems(item)}}):_vm._e(),(item.status === 'RUNNING')?_c('v-btn',{attrs:{"color":"error","icon":""},on:{"click":function($event){return _vm.terminateCurrentJobRun(item)}}},[_c('v-icon',[_vm._v(_vm._s(_vm.icons.mdiCloseOctagon))])],1):_vm._e()]}},{key:"expanded-item",fn:function(ref){
var headers = ref.headers;
var item = ref.item;
return [_c('td',{attrs:{"colspan":headers.length}},[(item.chipText === 'ERRORS')?_c('v-col',{attrs:{"sm":"12"}},[_c('v-alert',{attrs:{"type":"error"}},[_vm._v(" "+_vm._s(item.error)+" ")])],1):_vm._e(),_c('v-col',[_c('p',[_c('strong',[_vm._v("Schedule:")]),_vm._v(" "+_vm._s(item.cron === 'afterJob' ? ("After Previous Job Completion: " + (item.afterTaskText ? item.afterTaskText.name : '')) : item.enabled ? _vm.cronReadable(item) : 'DISABLED'))]),_c('p',[_c('strong',[_vm._v("Last Run Started:")]),_vm._v(" "+_vm._s(item.lastRunStartedAt))]),_c('p',[_c('strong',[_vm._v("Last Run Completed:")]),_vm._v(" "+_vm._s(item.lastRunCompletedAt))]),_c('p',[_c('strong',[_vm._v("Last Run Duration:")]),_vm._v(" "+_vm._s(item.lastRunDuration))])])],1)]}}])}),(_vm.connectorList.length === 0)?_c('v-row',{staticClass:"ma-5"},[_c('span',[_vm._v("No Connectors Have Been Created")])]):_vm._e()],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }